import React from "react"
import ArrowLeft from "../assets/icons/arrow-left.svg"

const Camp = () => {
  return (
    <div className="mx-10 mt-20 mb-36">
      <a className="items-center section-heading font-fontspring" href="/">
        <ArrowLeft className="mr-4" /> Back
      </a>
      <div className="flex flex-col items-center">
        <p className="max-w-3xl mt-10 text-xl text-azur">
          The Camp for Hardbooters only: Ride with Worldcup Professionals, learn
          how to carve and improve your carving skill!
        </p>
        <p className="max-w-3xl mt-10 text-xl text-center text-azur">
          This camp offers coaching and fun for Alpine Snowboard enthusiasts. No
          special level required. As long as you have some experience with
          hardboots, you are welcome!
        </p>
        <p className="max-w-3xl mt-10 text-xl text-center text-azur">
          Date: April 3-6, 2025
          <br /> Location: Scuol, Switzerland
        </p>
      </div>

      <img src="/assets/camp.webp" className="w-full mt-16" />
      <div className="flex flex-col items-center mt-16">
        <div className="md:w-[48rem] w-full">
          <h4 className="inline-flex text-3xl border-b-2 border-orange text-orange font-fontspring">
            Confirmed professionals, your coaches:
          </h4>
          <p className="mt-8 text-azur">
            NEVIN GALMARINI (SUI): Olympic Gold and Silver Medalist, Overall Worldcup Winner
            <br />
            RICARDA HAUSER (SUI): World and Europacup Racer, Local Hero
            <br />
            Further Athletes will be confirmed in due time
            <br />
          </p>
        </div>
        <div className="md:w-[48rem] w-full">
          <h4 className="inline-flex mt-32 text-3xl border-b-2 text-orange border-orange font-fontspring">
            Program
          </h4>
          <div className="mt-8 text-azur">
            <ol className="list-disc list-inside">
              <li>
                Thursday, April 3th
                <br />
                Arrival day, welcoming and dinner.
              </li>
              <li className="mt-4">
                Friday + Saturday, April 4th and 5th
                <br />
                Group riding and coaching in the morning and afternoon
              </li>
              <li className="mt-4">
                Sunday, April 6th
                <br />
                Special Event. End of camp in the early afternoon.
              </li>
            </ol>
          </div>
        </div>
        <div className="md:w-[48rem] w-full">
          <h4 className="inline-flex mt-32 text-3xl border-b-2 text-orange border-orange font-fontspring">
            Camp package
          </h4>
          <div className="mt-8 text-azur">
            <ol className="list-disc list-inside">
              <li>
                Coaching by Worldcup professionals (in English and German
                language)
              </li>
              <li>Oxess Snowboards testcenter</li>
              <li>
                Three nights accommodation Hotel Belvedere**** or Hotel
                Guardaval**** or Hotel Belvair***
              </li>
              <li>Breakfast and dinner in the Hotel Belvedere****</li>
              <li>
                Three days liftpass Scuol Motta Naluns (Ask for a free liftpass
                for Thursday if you arrive early)
              </li>
              <li>
                Free access to the Origym fitness center (Special recovery
                sessions)
              </li>
            </ol>
          </div>
        </div>
        <div className="md:w-[48rem] w-full">
          <h4 className="inline-flex mt-32 text-3xl border-b-2 text-orange border-orange font-fontspring">
            Prices
          </h4>
          <div className="mt-8 text-azur">
            <ol className="list-disc list-inside">
              <li>
                Single Room with camp package included: CHF 900.- per person
              </li>
              <li className="mt-4">
                Double Room with camp package included: CHF 775.- per person
              </li>
            </ol>
            <p className="mt-8">Excluded: Beverages, lunch and parking fee.</p>
          </div>
        </div>
        <div className="md:w-[48rem] w-full">
          <h4 className="inline-flex mt-32 text-3xl border-b-2 text-orange border-orange font-fontspring">
            Booking
          </h4>
          <div className="mt-8 text-azur">
            First come, first serve!
            <br /> Deadline: March 20, 2025
            <p className="mt-6 font-bold">
              Booking: only through
              <a
                className="ml-1 underline"
                href="mailto:info@ricardahauser.ch"
              >
                info@ricardahauser.ch
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Camp
